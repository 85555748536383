export enum RoutingPath {
  ROOT = '',
  PARADISE = 'paradise',
  MAIN = 'main',
  LOGIN = 'login',
  MEASURE_LOCATIONS = 'measure-locations',
  SELF_DECLARATION = 'self-declaration',
  CONSUMPTION = 'consumption',
  MESSAGES = 'messages',
  OVERVIEW = 'overview',
  IMPRESSUM = 'impressum',
  HELP = 'help',
}
